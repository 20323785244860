/**
 *
 * @param templateString : Endpoints URL with dynamic variable values
 * @param params  : Object containing value of the dynamic variable
 */

import { message } from "antd";
import { useRef } from "react";
import { MESSAGE_POPUP_TIME } from "./Constant";
let messageCount = 1;
export const fillTemplate = (templateString, params) => {
  let completedString = templateString;
  Object.keys(params).forEach((eachKeyName) => {
    completedString = completedString.replace(
      `:${eachKeyName}`,
      params[eachKeyName]
    );
  });
  return completedString;
};

export const browserDetails = (navigator) => {
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  // var details = {
  //   Browser_name: browserName,
  //   Full_version: fullVersion,
  //   Major_version: majorVersion,
  //   App_name: navigator.appName,
  //   User_Agent: navigator.userAgent,
  // };
  var qrData =
    browserName + "::" + fullVersion + "::" + new Date().toISOString();

  return qrData;
};

var cnt = 0;
var csv = "";
var csvCampDetails = "";

export const jsonToCsv = (jsonData, reportName) => {
  var json = jsonData[0];
  var fields = Object.keys(json).filter((data) => data != "details");
  // fields = fields.slice(1, fields.length);
  var replacer = function (key, value) {
    return value === null ? "" : value;
  };

  csv = jsonData.map(function (row) {
    return fields
      .filter((data) => data != "details")
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  fields = camelCasing(fields);
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\r\n");
  cnt = cnt + 1;
  if (cnt === 1 && jsonData[0].details) {
    csvCampDetails = csv;
    jsonToCsv(jsonData[0].details, reportName);
    return;
  }
  downloadCsv(cnt == 2 ? csvCampDetails + "\n\n" + csv : csv, reportName);
};

const camelCasing = (fields) => {
  let res = fields.map((field) => {
    return (
      field
        // insert a space before all caps
        .replace(/([A-Z])/g, " $1")
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  });
  return res;
};

const downloadCsv = (csv, reportName) => {
  var downloadLink = document.createElement("a");
  var blob = new Blob(["\ufeff", csv]);
  var url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = reportName
    ? reportName + "_" + "Report.csv"
    : "Report.csv"; //Name the file here
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  csv = "";
  csvCampDetails = "";
  cnt = 0;
};

export const jsonToCsvForFailedReport = (jsonData, reportName) => {
  var json = jsonData.details !== undefined ? jsonData.details : jsonData;
  var fields = Object.keys(json[0]);
  fields = fields.slice(1, fields.length);
  var replacer = function (key, value) {
    return value === null ? "" : value;
  };
  var csv = json.map(function (row) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\r\n");
  downloadCsv(csv, reportName);
};

export const showErrorPopup = (errorMessages) => {
  if (messageCount === 1) {
    message.error(errorMessages, MESSAGE_POPUP_TIME, () => {
      messageCount = 1;
    });
    messageCount = 0;
  }
};

export const showWarningPopup = (errorMessages) => {
  if (messageCount === 1) {
    message.warning(errorMessages, MESSAGE_POPUP_TIME, () => {
      messageCount = 1;
    });
    messageCount = 0;
  }
};

export const showSuccessPopup = (errorMessages) => {
  if (messageCount === 1) {
    message.success(errorMessages, MESSAGE_POPUP_TIME, () => {
      messageCount = 1;
    });
    messageCount = 0;
  }
};
export const timeZoneConverter = (date) => {
  const moment = require("moment");
  var testDateUtc = moment.utc(date);
  var localDate = moment(testDateUtc).local();
  return moment(localDate).format("LT");
};
