import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth-slice";
import campaignSlice from "./campaign-slice";


const store = configureStore({
  reducer: { auth: authSlice.reducer, campaign: campaignSlice.reducer },
});

export default store;
