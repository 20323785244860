import { CSpinner } from "@coreui/react";
import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./assets/style/css/coreui.min.css";
import "./App.css";

// import WhatsappCampaign from "../src/common/image/whatsapp-campaign.png";
import sidebarImage from "../src/common/image/123.png";
import AddContact from "../src/common/image/adding-contacts.png";
import CreatingContactList1 from "../src/common/image/creating-contact-list-1.png";
import CreatingContactList2 from "../src/common/image/creating-contact-list-2.png";
import AddingContactsToList from "../src/common/image/adding-contact-to-list.png";
import QrCodeScan1 from "../src/common/image/qr-code-scan-1.png";
import QrCodeScan2 from "../src/common/image/qr-code-scan-2.png";
import QrCodeScan3 from "../src/common/image/qr-code-scan-3.png";
import SMSCampaign1 from "../src/common/image/sms-campaign-1.png";
import SMSCampaign2 from "../src/common/image/sms-campaign-2.png";
import SMSCampaign3 from "../src/common/image/sms-campaign-3.png";
import WhatsappCampaign1 from "../src/common/image/whatsapp-campaign-1.png";
import WhatsappCampaign2 from "../src/common/image/whatsapp-campaign-2.png";
import WhatsappCampaign3 from "../src/common/image/whatsapp-campaign-3.png";
import MessageTemplate1 from "../src/common/image/message-template-1.png";
import MessageTemplate2 from "../src/common/image/message-template-2.png";
import ScheduleCampaign1 from "../src/common/image/schedule-campaign-1.png";
import ScheduleCampaign2 from "../src/common/image/schedule-campaign-2.png";

import CrossIcon from "../src/common/image/cross-icon.svg";
import One from "../src/common/image/one.svg";
import Two from "../src/common/image/two.svg";
import Three from "../src/common/image/three.svg";
import Four from "../src/common/image/four.svg";
import Five from "../src/common/image/five.svg";
import SideBarIcon1 from "../src/common/image/sidebar-icon-1.svg";
import SideBarIcon2 from "../src/common/image/sidebar-icon-2.svg";
import SideBarIcon3 from "../src/common/image/sidebar-icon-3.svg";
import SideBarIcon4 from "../src/common/image/sidebar-icon-4.svg";
import SideBarIcon5 from "../src/common/image/sidebar-icon-5.svg";
import Arrow from "../src/common/image/arrow.svg";
import Ellipsis from "../src/common/image/ellipses.svg";
// import PopupArrow from "../src/common/image/popup-arrow.svg";
import LpImage1 from "../src/icons/login/Group 1110 (1).svg";
import LpImage2 from "../src/icons/login/Group 1127.svg";
import LpImage3 from "../src/icons/login/Group 1101.png";
import QrCodeScan21 from "../src/common/image/qr-code-scan-2.1.png";

const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const loading = (
  <div className="spinner-div">
    <CSpinner />
  </div>
);
class App extends Component {
  componentDidMount() {
    const imagesToBePreloaded = [
      QrCodeScan21,
      LpImage1,
      LpImage2,
      LpImage3,
      // PopupArrow,
      Ellipsis,
      Arrow,
      SideBarIcon5,
      SideBarIcon4,
      SideBarIcon3,
      SideBarIcon2,
      SideBarIcon1,
      Five,
      Four,
      Three,
      Two,
      One,
      CrossIcon,
      sidebarImage,
      AddContact,
      CreatingContactList1,
      CreatingContactList2,
      AddingContactsToList,
      QrCodeScan1,
      QrCodeScan2,
      QrCodeScan3,
      SMSCampaign1,
      SMSCampaign2,
      SMSCampaign3,
      WhatsappCampaign1,
      WhatsappCampaign2,
      WhatsappCampaign3,
      MessageTemplate1,
      MessageTemplate1,
      MessageTemplate2,
      ScheduleCampaign1,
      ScheduleCampaign2,
    ];
    imagesToBePreloaded.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });
  }
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
