import { useEffect } from "react";

export const BASE_URL = process.env.REACT_APP_COMMON_URL;
//Contain List api used in application
export const MESSAGE_POPUP_TIME = 5;
export const DEFAULT_WAITING_TIME = 60;

// var userid = localStorage.getItem('curr_sender') !=null?localStorage.getItem('curr_sender'):"";

export const ENDPOINTS = {
  List: {
    GET_ALL: `user/:userId/lists`,
    GET: `user/:userId/list/:id`,
    ADD_CONTACTS_TO_LIST: `${BASE_URL}/user/:userId/list/:listId/add`,
    POST: `user/:userId/list`,
    REMOVE_CONTACTS: "user/:userId/list/:listId/remove",
    DELETE: `user/:userId/list/:listId`,
    EDIT: `user/:userId/list/:listId`,
  },
  CAMPAIGN: {
    GET_ALL: `user/:userId/campaigns`,
    GET: `user/:userId/campaign/:campaignId`,
    POST: `user/:userId/campaign`,
    PUBLISH: `user/:userId/campaign/:campaignId/publish`,
    SCHEDULE: `user/:userId/campaign/:campaignId/publish?scheduledDate=:date`,
    GETSTATUS: `user/:userId/campaign/:campaignId/status`,
    CREATEPUBLISH: `user/:userId/campaign/publish`,
    DELETE: `user/:userId/campaign/:campaignId`,
    GET_ALL_SENDERS: `/senders`,
    REPORT: `user/:userId/campaign/:campaignId/report`,
    UPDATE: `user/:userId/campaign/:campaignId`,
  },
  CHANNELS: {
    GET_ALL: `channels`,
  },
  CONTACT: {
    GET_ALL: `user/:userId/contacts`,
    DELETE: `user/:userId/contacts`,
    POST: `user/:userId/contacts`,
    ADD_CONTACT: `user/:userId/contact`,
  },
  WHATSAPP: {
    GET_STATUS: `whatsapp/:userId/status`,
    DISCONNECT: `whatsapp/:userId/status`,
  },
  AUTH: {
    LOGOUT: "user/:userId/logout",
  },
  MESSAGETEMPLATE: {
    GET_ALL: `user/:userId/templates`,
    GET: `user/:userId/template/:templateId`,
    CREATE: "user/:userId/template",
    DELETE: `user/:userId/template/:templateId`,
    EDIT: `user/:userId/template/:templateId`,
    POST: `user/:userId/template`,
    UPDATE: `user/:userId/template/:templateId`,
  },
  USER: {
    CREATE: `users`,
  },
};
